/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  StaticImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Link from "../components/link/link"
import SectionTitle from "../components/section-title/section-title"
import Metadata, { buildSocialImage } from "../components/metadata/metadata"
import Carousel from "../components/carousel/carousel"
import SquareList from "../components/list-square/list-square"

export default function PerformPlatform({ data }) {
  // const socialImage = buildSocialImage({
  //   title: data.imageSocial.name,
  //   ...getImage(data.imageSocial),
  // })

  return (
    <Layout>
      <Metadata
        title="Perform Analytics Platform"
        // description="Moth+Flame is an award-winning developer of immersive training technology, pioneering the next decade of virtual reality learning for the private and public sector."
        // image={socialImage}
      />
      <Panel sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }}>
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>
          Perform Analytics Platform
        </SectionTitle>
        <Themed.h1 className="scaleText" sx={{ gridColumn: "1 / -1" }}>
          Results you can see. One session is all it takes to build employee
          confidence.
        </Themed.h1>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mb: ["4rem", "unset"],
          }}
        >
          <Themed.p>
            Perform (our web portal) seamlessly integrates with Android, iOS,
            and PC devices to track the data you need in real-time.
          </Themed.p>
          <Themed.p>
            Leaders are equipped with an arsenal of data to make informed
            decisions and determine what actually works.
          </Themed.p>
          <Themed.p>
            We suggest using an optional Mobile Device Manager (MDM) like{" "}
            <Link to="https://arborxr.com/" target="_blank">
              ArborXR
            </Link>{" "}
            to easily manage a fleet of devices, controlling settings and
            content.
          </Themed.p>
          <SquareList>
            <li>
              Glean insights from{" "}
              <strong>real-time performance analytics & KPI dashboard</strong>
            </li>
            <li>
              <strong>Deploy and manage</strong> learning programs for multiple
              cohorts across multiple locations
            </li>
            <li>Multi-department, multi-role permission levels</li>
            <li>
              <strong>Manage VR and mobile devices</strong>, pushing content to
              device groups
            </li>
            <li>
              <strong>Compare learning performance</strong> across different
              groups in the company
            </li>
            <li>
              <strong>Cloud deployment</strong> for real-time updates
            </li>
            <li>
              <strong>Data encrypted</strong> in transit and at rest
            </li>
            <li>
              <strong>Secure device registration</strong> to safeguard sensitive
              content
            </li>
            <li>Only PII stored: name & email</li>
            <li>
              Supports interoperability (no data lock) with{" "}
              <strong>on-demand data export into csv and xlsx</strong>
            </li>
          </SquareList>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            px: [null, "1rem", "4rem"],
            pt: [null, "8rem", 0],
            perspective: [null, "500px"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/home-first-panel/4-no-code-content-creation.png"
            alt="Module builder"
            sx={{
              transform: [null, "rotateY(-4deg) skewY(1deg)"],
            }}
          />
        </div>
      </Panel>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    imageSocial: file(name: { eq: "mask-social" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED, formats: JPG)
      }
    }
  }
`
